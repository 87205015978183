var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_c('b-img',{attrs:{"fluid":"","src":_vm.logoImg,"alt":"Logo da Agenda"}})],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-2",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Esqueceu a Senha"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Esqueceu sua senha? 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Insira seus dados a abaixo para resetar sua senha. ")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"forgot-password-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-email","state":errors.length > 0 ? false : null,"name":"forgot-password-email","placeholder":"john@example.com"},model:{value:(_vm.dados.email),callback:function ($$v) {_vm.$set(_vm.dados, "email", $$v)},expression:"dados.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Data Nascimento","label-for":"dataNascimento"}},[_c('validation-provider',{attrs:{"name":"Data Nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"dataNascimento","type":"text","placeholder":"DD/MM/AAAA","options":_vm.options.cleaveDate,"raw":false},model:{value:(_vm.dados.dataNascimento),callback:function ($$v) {_vm.$set(_vm.dados, "dataNascimento", $$v)},expression:"dados.dataNascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"telefone","label":"Telefone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"telefone","raw":false,"options":_vm.options.clevePhone,"placeholder":"Telefone","rules":"required"},model:{value:(_vm.dados.telefone),callback:function ($$v) {_vm.$set(_vm.dados, "telefone", $$v)},expression:"dados.telefone"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Enviar ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Volte a tela de login ")],1)],1)],1)],1)],1),_c('b-modal',{ref:"modal-auth-resetaSenha",attrs:{"id":"modal-auth-resetaSenha","centered":"","size":"sm","no-close-on-backdrop":"","no-close-on-esc":"","hide-backdrop":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"auth-inner px-2 py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('h3',{staticClass:"text-primary text-center mb-2",attrs:{"title-tag":"h4"}},[_vm._v("Redefina sua senha")]),_c('b-card-text',{staticClass:"mb-3"},[_vm._v(" Sua nova senha precisa ser diferente das anteriores ")]),_c('validation-observer',{ref:"formSenha"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2"},[_c('b-form-group',{attrs:{"label":"Nova Senha","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Nova Senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.campoSenhaNovo,"state":errors.length > 0 ? false : null,"name":"reset-password-new","placeholder":"Digite sua nova senha"},model:{value:(_vm.senhaNova),callback:function ($$v) {_vm.senhaNova=$$v},expression:"senhaNova"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.campoSenhaNovo},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Confirme a nova senha"}},[_c('validation-provider',{attrs:{"name":"Confirme a nova senha","rules":{
                  required: true,
                  camposIguais: _vm.senhaNova,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.cCampoSenhaNovo,"state":errors.length > 0 ? false : null,"name":"reset-password-confirm","placeholder":"Confirme sua atual senha"},model:{value:(_vm.confirmaSenhaNova),callback:function ($$v) {_vm.confirmaSenhaNova=$$v},expression:"confirmaSenhaNova"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.cCampoSenhaNovo},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('p',{staticClass:"text-center"},[_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.salvar}},[_vm._v(" Salvar nova senha ")])],1)],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Volte ao login ")],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }